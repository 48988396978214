<template>
    <div>
        <Quote/>
        <GoBack next="Promo kod ishlatilish tarixi"/>
        <div ref="top">
            <h3 class="text-center mb-4 mt-4">
                Promokod: {{ getPromoCode.promo }}
            </h3>
        </div>
        <div class="row justify-content-center mt-5 pt-0">
            <div v-if="getPromoCodeUsedTotalItems > 0" class="col-12 col-md-9 p-1">
                <b-table-simple class="table border">
                    <b-thead>
                        <tr>
                            <th>
                                <input
                                    v-model="search.courseName"
                                    type="text"
                                    @keyup.enter="searchAboutPromoCode"
                                    placeholder="Kurs"
                                    style="width: 135px;border-radius: 5px"
                                    class="col-8"
                                />
                            </th>
                            <th class="d-none d-lg-table-cell">
                                <input
                                    v-model="search.familyName"
                                    type="text"
                                    @keyup.enter="searchAboutPromoCode"
                                    placeholder="Familyasi"
                                    style="width: 130px;border-radius: 5px"
                                    class="col"
                                />
                            </th>
                            <th>
                                <input
                                    v-model="search.givenName"
                                    type="text"
                                    @keyup.enter="searchAboutPromoCode"
                                    placeholder="Ismi"
                                    style="width: 135px;border-radius: 5px"
                                    class="col-8"
                                />
                            </th>
                            <th class="col text-end text-md-center" style="width: 100px">
                                <b-button
                                    v-b-tooltip.hover
                                    title="Izlash"
                                    class="border-0 p-0"
                                    style="background-color: #FFFFFF; font-size:1.6vw;"
                                >
                                    <img
                                        @click="searchAboutPromoCode"
                                        style=" height: auto; cursor: pointer; font-size:1.6vw;"
                                        src="../assets/search-icon.png"
                                        alt=""
                                    >
                                </b-button>
                                <b-button
                                    v-b-tooltip.hover
                                    title="Tozalash"
                                    class="border-0 p-0 ms-1"
                                    style="background-color: #FFFFFF; font-size:1.7vw;"
                                >
                                    <img
                                        @click="resetSaleSearchPromo"
                                        style=" height: auto; cursor: pointer; font-size:1.7vw;"
                                        src="../assets/eraser-icon.png"
                                        alt=""
                                    >
                                </b-button>
                            </th>
                        </tr>
                    </b-thead>

                    <b-tbody>
                        <tr
                            class=" pt-3 pb-3"
                            v-for="promo in getPromoCodeUsed"
                            :key="promo.id"
                        >
                            <td>
                                <router-link
                                    v-if="promo.course.type.category.name === 'web-junior'"
                                    :to="'/course'"
                                    style="text-decoration: none"
                                >
                                    <span class="texts hover-decoration">{{ promo.course.name }}</span>
                                </router-link>
                                <router-link
                                    :to="'/course-middle'"
                                    v-if="promo.course.type.category.name === 'web-middle'"
                                    id="goToPurchase"
                                    style="text-decoration: none"
                                >
                                    <span class="texts hover-decoration">{{ promo.course.name }}</span>
                                </router-link>
                            </td>
                            <td class="d-none d-lg-table-cell">
                                <router-link :to="'/edit-admin-user/' + promo.user.id" style="text-decoration: none">
                                    <span class="texts hover-decoration">{{ promo.user.familyName }}</span>
                                </router-link>
                            </td>
                            <td colspan="2">
                                <router-link
                                    :to="'/edit-admin-user/' + promo.user.id"
                                    style="text-decoration: none"
                                >
                                    <span class="texts hover-decoration">{{ promo.user.givenName }}</span>
                                </router-link>
                            </td>
                        </tr>
                    </b-tbody>
                </b-table-simple>
            </div>
            <div v-else class="text-center">
                <h4>Ushbu promokod hozircha ishlatilmagan</h4>
            </div>
        </div>

        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </div>
</template>

<script>
import Quote from "@/components/Quote";
import GoBack from "@/components/GoBack";
import {mapActions, mapGetters} from "vuex";
import accessMixin from "@/mixin/accessMixin";

export default {
    name: "Add-CoursePage",
    mixins: [accessMixin],
    components: {GoBack, Quote},
    data() {
        return {
            search: {
                promoId: '',
                givenName: '',
                familyName: '',
                courseName: '',
                page: 1
            },
            show: true,

        }
    },
    computed: {
        ...mapGetters([
            'getPromoCodeUsed',
            'getPromoCodeUsedTotalItems',
            'getSearchPromoCodeUse',
            'getUsersList',
            'getPromoCode'
        ]),
    },

    methods: {
        ...mapActions([
            'fetchPromoCodeUsed',
            'searchPromoCodeCount',
            'pushCourseType',
            'fetchUsers',
            'fetchPromoCode'
        ]),
        searchAboutPromoCode() {
            this.show = true
            this.search.promoId = this.$route.params.id
            this.searchPromoCodeCount(this.search)
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.show = false
                });
        },
        resetSaleSearchPromo() {
            Object.assign(this.$data, this.$options.data.call(this));
            this.searchAboutPromoCode()
        },
    },

    mounted() {
        this.show = true
        this.fetchPromoCodeUsed(this.$route.params.id)
        this.show = false
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@600&display=swap');

* {
    padding: 0;
    margin: 0;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-repeat: no-repeat;
    background-size: 25px !important;
}

select:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

select:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

select::-ms-expand {
    display: none !important;
}

section form {
    font-family: Comfortaa, cursive;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-top: 100px;
    margin-bottom: 100px;
}

img {
    width: 20px;
    height: 20px;
}

input {
    border: 1px solid black;
}

input::placeholder {
    font-size: 12px;
    padding: 0.5rem;
}

.mb-3 .form-control {
    border-radius: 10px;
    border: solid #80007F 1px;
    font-family: Comfortaa, cursive;
}

.mb-3 .form-control-select {
    border-radius: 0 10px 10px 0;
    border: solid #80007F 1px;
    font-family: Comfortaa, cursive;
}

.mb-3 .form-control-input {
    border-radius: 10px 0 0 10px;
    border: solid #80007F 1px;
    border-right: none;
    font-family: Comfortaa, cursive;
}

.justify-content-end .btn-primary {
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 10px;
    background-color: #80007F;
    font-family: Comfortaa, cursive;
    font-size: 16px;
}

img {
    width: 20px;
    height: 20px;
}

input {
    border: 1px solid black;
    padding-left: 0.5rem;
}

input::placeholder {
    font-size: 13px;
    padding: 0.5rem;
}

@media (max-width: 450px) {
    .texts {
        word-break: break-all;
        font-size: 13px;
    }

    input {
        font-size: 4vw;
        margin: 0;
    }
}
</style>
